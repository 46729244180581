import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import { blogList } from "../../data/blogs";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/how-to-stage-your-san-diego-home");

  return (
    <LayoutWrapper
      title="How To Stage Your Home to Sell Quickly"
      desc="If you want to know how you can stage your San Diego home so that it sells quickly there are a few simple things you can do to make it happen."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              How To Stage Your Home to Sell Quickly
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Jan 13, 2024</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Stage San Diego Home to Sell Quickly.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <a
                  href="https://selbysellssd.com/sell-your-san-diego-home"
                  target="_Blank"
                  className="text-blue-500"
                >
                  Selling your home
                </a>{" "}
                takes lots of time and attention, so no one wants to wait months and months to find
                the right buyer. Additionally, the longer you wait for your home to sell, the longer
                it might take for you to be able to move on to your next property.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                How can I stage my San Diego home so that it sells quickly? If you want your home
                sold promptly for a great price, there are a few simple things you can do to make it
                happen.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Know Your Market
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The current state of your local housing market will play a massive role in how
                quickly and successfully you can sell. Is it a seller’s market or a buyer’s market?
                Are high mortgage and interest rates disincentivizing potential buyers?
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Research the current climate of San Diego real estate and the history of home sales
                in your neighborhood. You may notice a trend surrounding the times of year when
                houses sell.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Stage the Home
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Once you’ve decided that you’re going to list your home for sale, it’s time to
                reimagine the decor to entice a buyer. Potential buyers need to be able to imagine
                themselves in the space before they can feel compelled to put in an offer.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As you prepare to show your home, try to balance a personalized living space and a
                perfectly staged home. Remove enough personal items to make it feel more uncluttered
                and neat, but keep the decor that helps make the space warm and inviting. Striking
                this balance will create the perfect setup for all your public and private home
                showings.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Invest in Pro Photos
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Much of your marketing efforts will take place online during the selling process.
                These days, online home buying and selling platforms are a significant part of the
                real estate market.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                San Diego is full of attractive and interesting homes, and you want your listing to
                stand out from the crowd and entice the perfect buyer. That’s why you should invest
                in a professional photographer and videographer to capture high-quality images and
                videos of your home. These will allow potential buyers to see your home the way you
                want it to be seen.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Boost Curb Appeal
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Your home may be stunning inside, but if it doesn’t catch a buyer’s eye from the
                street, you’ll likely have trouble selling it quickly. Potential buyers want the
                home’s exterior to be just as appealing as the layout and design of the interior.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                There are so many ways to quickly and easily boost your home’s curb appeal. Ensure
                your landscaping is well-kept, and maybe even make some updates as you prepare to
                list your home. Remove any clutter, such as toys or gardening tools, and keep
                garbage bins out of sight.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Don’t Be Afraid to Negotiate
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It’s very unusual for real estate deals to close without price negotiations. As the
                seller, be prepared to negotiate back and forth with the potential buyers who put in
                offers on your home. Be flexible and willing to work with their needs, but keep your
                needs at the forefront of your negotiations. This will ensure that you walk away
                from the deal satisfied.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Work With the Right Real Estate Agent
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You need a real estate pro on your team that understands the San Diego housing
                market inside and out. When you work with us, you can rest assured that you’ll sell
                your home quickly for a price you’ll love.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ContactSlideoutLink text="Contact the Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
